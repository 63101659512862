<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav -->
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title">Umumiy</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Ilova</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Buyurtma</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Dop soglashenie</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>

              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Bajarilgan ish</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">Hisob Faktura</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">To'lovlar</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
          <div v-if="loading" class="text-primary text-center my-5">
            <b-spinner
              style="width: 3rem; height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </div>
          <!--begin: Wizard Body-->
          <div v-if="!loading" class="row justify-content-center px-8 px-lg-10">
            <div class="col-12">
              <!--begin: Wizard Form-->
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    Shartnoma malumotlari
                  </h4>
                  <div class="row flex-wrap">
                    <div class="form-group col-4 mb-0">
                      <label>Mijoz nomi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.client_name"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>INN</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.client_inn"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma turi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.contract_type"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>To'lov turi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.payment_type"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma raqami</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.contract_number"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Shartnoma bo'lgan sanasi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.start_date | formatDate"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Summa</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.amount"
                      />
                    </div>
                    <div class="form-group col-4">
                      <label>Foaligi</label>
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-solid form-control-lg"
                        :value="list.is_active_name"
                      />
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    class="
                      mb-10
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <h4 class="font-weight-bold text-dark">
                      Ilovalar ro'yxati
                    </h4>
                  </div>
                  <div>
                    <contractappen
                      :data="list.contract_appendix"
                    ></contractappen>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      list.contract_zayavka !== undefined &&
                      list.contract_zayavka.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Buyurtmalar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Buyurtmalar
                    </h4>
                    <v-data-table
                      :headers="zayavkaList"
                      :items="list.contract_zayavka"
                      class="elevation-1"
                      no-data-text="Malumot mavjud emas"
                    >
                      <template v-slot:[`item.payment_type_name`]="{ item }">
                        <div
                          class="payment__name"
                          :class="item.payment_text_color"
                        >
                          <span
                            :class="[item.payment_color_label]"
                            class="label label-dot"
                          ></span>
                          {{ item.payment_type_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.zayavka_status`]="{ item }">
                        <div class="status__name" :class="item.status_style">
                          {{ item.zayavka_status_name }}
                        </div>
                      </template>
                      <!-- <template v-slot:[`item.actions`]="{ item }">
                        <ZayavkaAction :index="item"></ZayavkaAction>
                      </template> -->
                    </v-data-table>
                  </div>
                </div>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 4-->
                <div class="pb-5" data-wizard-type="step-content">
                  <!-- <div>
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Kelishuv shartnomalar topilmadi
                    </h4>
                  </div>
                  <div>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Kelishuv shartnomalar
                    </h4>
                    <div>
                      <v-data-table
                        :headers="headers"
                        :items="getCorrDetails"
                        :items-per-page="5"
                      ></v-data-table>
                    </div>
                  </div> -->
                </div>
                <!--end: Wizard Step 4-->

                <!--begin: Wizard Step 5-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      list.completed_jobs !== undefined &&
                      list.completed_jobs.length !== undefined &&
                      list.completed_jobs.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Bajarilgan ishlar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <div
                      class="
                        mb-10
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <h4 class="font-weight-bold text-dark">
                        Bajarilgan ishlar
                      </h4>
                    </div>
                    <div class="v-data-table__wrapper">
                      <v-data-table
                        :loading="isLoading"
                        v-model="selected"
                        :headers="headersJob"
                        :items="list.completed_jobs"
                        :items-per-page="5"
                      >
                        <template v-slot:[`item.payment_type_name`]="{ item }">
                          <div :class="item.payment_text_color">
                            <span
                              v-bind:class="[item.payment_color_label]"
                              class="label label-dot"
                            ></span>
                            {{ item.payment_type_name }}
                          </div>
                        </template>

                        <template v-slot:[`item.direction_cities`]="{ item }">
                          <span
                            v-for="(dest, index) in item.direction_cities"
                            :key="dest.id"
                          >
                            {{ dest.city_name.name }}
                            <span
                              v-if="index == item.direction_cities.length - 1"
                            ></span>
                            <span v-else>-</span>
                          </span>
                        </template>

                        <template v-slot:[`item.start_date`]="{ item }">
                          {{ item.start_date.slice(0, 10) }}
                        </template>

                        <template v-slot:[`item.end_date`]="{ item }">
                          {{ item.end_date.slice(0, 10) }}
                        </template>
                        <template v-slot:[`item.price`]="{ item }">
                          {{ item.price.toLocaleString('zh-ZH') }}
                        </template>

                        <template v-slot:[`item.nds_summa`]="{ item }">
                          {{ item.nds_summa.toLocaleString('zh-ZH') }}
                        </template>

                        <template v-slot:[`item.price_with_nds`]="{ item }">
                          {{ item.price_with_nds.toLocaleString('zh-ZH') }}
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 5-->

                <!--begin: Wizard Step 6-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      list.contract_facturas !== undefined &&
                      list.contract_facturas.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      Hisob fakturalar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Hisob fakturalar
                    </h4>
                    <v-data-table
                      :headers="headers222"
                      no-data-text="Malumot mavjud emas"
                      :items="list.contract_facturas"
                    >
                      <!-- <template v-slot:[`item.action`]="{ item }">
                        <FacturaAction :index="item" />
                      </template> -->

                      <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.price !== null">
                          {{
                            item.get_factura_amount.price.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.nds`]="{ item }"
                      >
                        <div v-if="item.get_factura_amount.nds !== null">
                          {{
                            item.get_factura_amount.nds.toLocaleString('zh-ZH')
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                      <template
                        v-slot:[`item.get_factura_amount.price_with_nds`]="{
                          item
                        }"
                      >
                        <div
                          v-if="item.get_factura_amount.price_with_nds !== null"
                        >
                          {{
                            item.get_factura_amount.price_with_nds.toLocaleString(
                              'zh-ZH'
                            )
                          }}
                        </div>
                        <div v-else>0</div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <!--end: Wizard Step 6-->
                <!--begin: Wizard Step 7-->
                <div class="pb-5" data-wizard-type="step-content">
                  <div
                    v-if="
                      list.contract_operations_info !== undefined &&
                      list.contract_operations_info.length == 0
                    "
                  >
                    <h4 class="mb-10 font-weight-bold text-danger">
                      To'lovlar topilmadi
                    </h4>
                  </div>
                  <div v-else>
                    <payments :data="list.contract_operations_info" />
                  </div>
                </div>
                <!--end: Wizard Step 7-->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTWizard from '@/assets/js/components/wizard'
import contractappen from './components/contractappen'
import payments from './components/payments.vue'
// import KTUtil from '@/assets/js/components/util'
// import KTWizard from '@/assets/js/components/wizard'
export default {
  data() {
    return {
      list: {},
      zayavkaList: [
        { text: 'ID', value: 'id' },
        { text: 'Buyurtma ownrer', value: 'zayavka_owner' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Narxi', value: 'price' },
        { text: 'Kg', value: 'post_kg' },
        { text: 'm3', value: 'post_m3' },
        { text: 'Status', value: 'zayavka_status' }
      ],
      headersJob: [
        { text: 'Id', value: 'id' },
        { text: "Yo'nalishi", value: 'direction_cities' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Boshlanish', value: 'start_date' },
        { text: 'Tugash', value: 'end_date' },
        { text: 'Summa', value: 'price' },
        { text: 'Nds Summa', value: 'nds_summa' },
        { text: 'Summa(Nds)', value: 'price_with_nds' }
      ],
      headers222: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Mijoz',
          value: 'client_name'
        },
        {
          text: 'Raqami',
          value: 'reg_number'
        },
        {
          text: 'Shartnoma',
          value: 'contract'
        },
        {
          text: 'Narxi',
          value: 'get_factura_amount.price'
        },
        {
          text: 'Nds',
          value: 'get_factura_amount.nds'
        },
        {
          text: 'narxi(nds)',
          value: 'get_factura_amount.price_with_nds'
        },
        {
          text: 'Soni',
          value: 'get_factura_amount.count'
        },
        {
          text: 'Sana',
          value: 'created_at'
        }
      ]
    }
  },
  components: {
    contractappen,
    payments
  },
  created() {
    this.$store.dispatch('getClientPageContractDetails', this.$route.params.id)
  },
  computed: {
    getDetails() {
      return this.$store.state.clientStore.clientContractDetails
    },

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },

  methods: {
    initWizard() {
      this.$nextTick(() => {
        // Initialize form wizard
        const wizard = new KTWizard('kt_wizard_v3', {
          startStep: 1, // initial active step number
          clickableSteps: true // allow step clicking
        })
        // Validation before going to next page
        wizard.on('beforeNext', function (/*wizardObj*/) {
          // validate the form and use below function to stop the wizard's step
          // wizardObj.stop();
        })
      })
    }
  },
  watch: {
    getDetails(val) {
      this.list = {}
      this.list = val
    },

    isLoading(val) {
      if (!val) {
        this.initWizard()
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Shartnoma malumotlari' }])
    this.initWizard()
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>
<style scoped>
.overlay_style {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  background-color: #333;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: inherit;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
}
tbody tr:nth-child(odd) {
  background: #eee;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.wizard-step {
  padding: 0;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
