<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      class="elevation-1"
      :loading="isLoading"
      no-data-text="Malumot mavjud emas"
      :page.sync="page"
      loading-text="..."
      :items-per-page="5"
      hide-default-footer
    >
      <template v-slot:[`item.transfer_name`]="{ item }">
        <div :class="item.transfer_name_class">
          <span
            :class="item.transfer_name_class_label"
            class="label-primary label label-dot"
          ></span>
          {{ item.transfer_name }}
        </div>
      </template>
    </v-data-table>
    <!-- <div class="text-center">
      <v-pagination
        v-model="page"
        :length="Math.ceil(data.length / 5)"
      ></v-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      headers: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Turi',
          value: 'transfer_name'
        },
        {
          text: 'Summa',
          value: 'amount'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Tranzaksiya',
          value: 'transfer'
        }
      ]
    }
  },
  props: {
    data: Array
  }
}
</script>

<style></style>
